import React from "react";
import OOPSImage from "./assets/oops.png";
const Oops = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center font-bold w-full  h-screen">
      <img src={OOPSImage} alt="oops page" className="w-1/3" />
      <h1 className="text-2xl md:text-3xl lg:text-5xl text-primary capitalize">
        oops!
      </h1>
      <p className="text-2xl md:text-3xl lg:text-4xl font-normal text-primary/40 capitalize">
        something went wrong
      </p>
    </div>
  );
};

export default Oops;
