import molsVideo from "./assets/Sample.mp4";

// import BorderImage from "./assets/trail.png";

const Slide = () => {
  //   const images = [
  //     {
  //       url: "https://images.unsplash.com/photo-1572888195250-3037a59d3578?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZXRoaW9waWF8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //     },
  //     {
  //       url: "https://images.unsplash.com/photo-1614981816670-3e65f4cfdb28?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZXRoaW9waWF8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //     },
  //     {
  //       url: "https://images.unsplash.com/photo-1624314138470-5a2f24623f10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZXRoaW9waWF8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //     },
  //     {
  //       url: "https://images.unsplash.com/photo-1630861412757-d9743d318312?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8ZXRoaW9waWF8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //     },
  //   ];
  return (
    // <SimpleImageSlider
    //   width={"100%"}
    //   height={"100vh"}
    //   images={images}
    //   showBullets={false}
    //   showNavs={false}
    //   autoPlay={true}
    // />

    <div className="w-screen h-full relative">
      <video src={molsVideo} autoPlay muted loop className="w-screen"></video>
    </div>
  );
};

export default Slide;
