import Biden from "./assets/biden.png";
import mols from "./assets/mols.png";
import BorderImage from "./assets/trail.png";
const Home = () => {
  return (
    <div className="h-screen w-full flower-border bg-gradient-to-b text-[#3170B5] from-white to-yellow-100">
      <img
        alt="flower"
        src={BorderImage}
        className=" absolute top-0 left-0  h-full"
      />
      <img
        alt="flower"
        src={BorderImage}
        className=" absolute top-0 right-0 -rotate-180  h-full"
      />

      <ul className="login-circles">
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
        <li>
          <img alt="flower" src={Biden} />
        </li>
      </ul>

      <div className="flex items-center justify-end flex-col h-full font-semibold">
        <h1 className="text-[5vw] capitalize">system launching</h1>
        <p className=" capitalize text-[5vw] -mt-4">ceremony</p>
        <img alt="flower" src={mols} className="w-1/5 mt-[35vh] mb-12" />
      </div>
    </div>
  );
};

export default Home;
